import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import classes from "./index.module.scss";
import Spinner from "@/components/elements/Spinner";

export default function Home() {
    const { data: session, status } = useSession();
    const router = useRouter();

    useEffect(() => {
        if (status === "authenticated") {
            router.push("/app");
        } else if (status === "unauthenticated") {
            router.push("/login");
        }
    }, [status, router]);

    // Optional: You can return null or a loading indicator while waiting for the session status
    return (
        <div className={classes.loaderBody}>
            <Spinner />
        </div>
    );
}
